import { default as indexnOfUzly1f4Meta } from "/vercel/path0/internal-dashboard/pages/auth/index.vue?macro=true";
import { default as validate_45emailjo0RGASd5eMeta } from "/vercel/path0/internal-dashboard/pages/auth/validate-email.vue?macro=true";
import { default as financeQsXoD9bcw2Meta } from "/vercel/path0/internal-dashboard/pages/finance.vue?macro=true";
import { default as index0Py8deMemTMeta } from "/vercel/path0/internal-dashboard/pages/index.vue?macro=true";
import { default as network7n2iBvsLyjMeta } from "/vercel/path0/internal-dashboard/pages/network.vue?macro=true";
import { default as usersit7tciwzSCMeta } from "/vercel/path0/internal-dashboard/pages/users.vue?macro=true";
export default [
  {
    name: indexnOfUzly1f4Meta?.name ?? "auth",
    path: indexnOfUzly1f4Meta?.path ?? "/auth",
    meta: indexnOfUzly1f4Meta || {},
    alias: indexnOfUzly1f4Meta?.alias || [],
    redirect: indexnOfUzly1f4Meta?.redirect,
    component: () => import("/vercel/path0/internal-dashboard/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: validate_45emailjo0RGASd5eMeta?.name ?? "auth-validate-email",
    path: validate_45emailjo0RGASd5eMeta?.path ?? "/auth/validate-email",
    meta: validate_45emailjo0RGASd5eMeta || {},
    alias: validate_45emailjo0RGASd5eMeta?.alias || [],
    redirect: validate_45emailjo0RGASd5eMeta?.redirect,
    component: () => import("/vercel/path0/internal-dashboard/pages/auth/validate-email.vue").then(m => m.default || m)
  },
  {
    name: financeQsXoD9bcw2Meta?.name ?? "finance",
    path: financeQsXoD9bcw2Meta?.path ?? "/finance",
    meta: financeQsXoD9bcw2Meta || {},
    alias: financeQsXoD9bcw2Meta?.alias || [],
    redirect: financeQsXoD9bcw2Meta?.redirect,
    component: () => import("/vercel/path0/internal-dashboard/pages/finance.vue").then(m => m.default || m)
  },
  {
    name: index0Py8deMemTMeta?.name ?? "index",
    path: index0Py8deMemTMeta?.path ?? "/",
    meta: index0Py8deMemTMeta || {},
    alias: index0Py8deMemTMeta?.alias || [],
    redirect: index0Py8deMemTMeta?.redirect,
    component: () => import("/vercel/path0/internal-dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: network7n2iBvsLyjMeta?.name ?? "network",
    path: network7n2iBvsLyjMeta?.path ?? "/network",
    meta: network7n2iBvsLyjMeta || {},
    alias: network7n2iBvsLyjMeta?.alias || [],
    redirect: network7n2iBvsLyjMeta?.redirect,
    component: () => import("/vercel/path0/internal-dashboard/pages/network.vue").then(m => m.default || m)
  },
  {
    name: usersit7tciwzSCMeta?.name ?? "users",
    path: usersit7tciwzSCMeta?.path ?? "/users",
    meta: usersit7tciwzSCMeta || {},
    alias: usersit7tciwzSCMeta?.alias || [],
    redirect: usersit7tciwzSCMeta?.redirect,
    component: () => import("/vercel/path0/internal-dashboard/pages/users.vue").then(m => m.default || m)
  }
]