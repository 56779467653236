import revive_payload_client_Frd8gASYWa from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aQoh9E5uaZ from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/internal-dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_kCUITUWXUN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import chart_3lUUfbbx0f from "/vercel/path0/internal-dashboard/plugins/chart.ts";
import primevue_7rYYRZQLyx from "/vercel/path0/internal-dashboard/plugins/primevue.ts";
import trpc_0KeCZOBKLF from "/vercel/path0/internal-dashboard/plugins/trpc.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  check_outdated_build_client_yxgjJ9XxnV,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  chunk_reload_client_kCUITUWXUN,
  chart_3lUUfbbx0f,
  primevue_7rYYRZQLyx,
  trpc_0KeCZOBKLF
]