import PrimeVue from 'primevue/config'

export default defineNuxtPlugin((nuxtApp) => {
  console.log('app', nuxtApp)
  nuxtApp.vueApp.use(PrimeVue as any, {
    unstyled: true,
    pt: {
      datatable: {
        table: {
          class: 'w-full'
        },
        thead: {
          class: 'text-[#626262] font-space uppercase text-[12px]'
        },
        // bodyRow: {
        //   class: 'hover:bg-white/5'
        // },
        column: {
          headerContent: {
            class: 'pb-6 text-center font-semibold'
          },
          headerTitle: {
            class: 'text-[14px]'
          },
          bodyCell: {
            class:
              'py-5 px-4 font-eurostile text-center text-[12px] border-b text-grayscale-60 border-b-[#3A3B3B]'
          }
        }
      }
    }
  })
})
