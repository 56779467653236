export default defineNuxtRouteMiddleware(async (from, _to) => {
  console.log('middleware')
  const trpc = useTRPC()
  try {
    const user = await trpc.user.iam.query()
    // console.log('USER', user)
    if (user.kind !== 'Admin' || !user) {
      console.log('redirecting')
      return '/auth?to=' + encodeURIComponent(from.fullPath)
    }
  } catch (error) {
    console.log('error', error)
    return '/auth?to=' + encodeURIComponent(from.fullPath)
  }
})
