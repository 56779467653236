<script lang="ts" setup>
import LogoutIcon from '~/assets/icons/logout.svg'

const trpc = useTRPC()
const user = await trpc.user.iam.query()
console.log(user)

const logout = () => {
  localStorage.removeItem('access')
  localStorage.removeItem('refresh')
  window.location.reload()
}
</script>

<template>
  <div
    class="rounded-xl border w-fit border-grayscale-7 bg-[#111213] pl-4 py-1 pr-1"
  >
    <div class="flex items-center gap-4">
      <div class="flex flex-col gap-1 uppercase text-right">
        <p class="text-[#B4B6B6] text-[11px] font-light">
          administrator
        </p>
        <p
          class="text-[8px] tracking-[2px] text-white font-medium font-[monospace]"
        >
          {{ user.email }}
        </p>
      </div>
      <button class="p-3 rounded-[4px]" @click="logout">
        <img :src="LogoutIcon" alt="">
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-bg {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 102.72%
  );
  backdrop-filter: blur(1.69px);
}
</style>
