<template>
  <div class="size-full min-h-screen p-8 bg-[#0A070B]">
    <slot />
    <!--123-->
  </div>
</template>
<style>
body {
  background-color: #000;
}
</style>
