<template>
  <div class="grid grid-rows-[auto_1fr] bg-[#010101] size-full min-h-screen">
    <Header />
    <div class="p-8">
      <slot />
    </div>
  </div>
</template>
<style>
body {
  background-color: #000;
}
</style>
