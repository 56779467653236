<template>
  <div
    class="flex items-center w-fit gap-1 p-1 border border-grayscale-7 bg-[#111213] rounded-[4px] uppercase"
  >
    <RouterLink class="router-link" to="/">
      Analytics
    </RouterLink>
    <RouterLink class="router-link" to="/users">
      Users
    </RouterLink>
    <RouterLink class="router-link" to="/finance">
      Finance
    </RouterLink>
    <RouterLink class="router-link" to="/network">
      Network
    </RouterLink>
  </div>
</template>
<style lang="scss" scoped>
.router-link-exact-active {
  background-color: #1f1f1f;
  color: #ffffff;
}
.router-link {
  color: #ccc;
  font-family: eurostile;
  font-size: 11px;
  padding: 8px 24px;
  transition: all 0.88s;
  &:hover {
    background-color: #fff;
    color: #000;
  }
}
// class="p-6 text-[12px] font-eurostile text-[#B4B6B6] transition-all"
</style>
