import { createTRPCNuxtClient, httpBatchLink } from 'trpc-nuxt/client'
import type { AppRouter } from '@snowstorm/web/server/trpc/routers'
import { TRPCClientError } from '@trpc/client'

// const isProduction = process.env.NODE_ENV === 'production'
// export const url =
// process.env.SNOWSTORM_API || isProduction
// ? 'https://api.snowstorm.net/api'
// : 'https://api.dev.snowstorm.net/api'

// TODO(keroserene) - HACK UNTIL DEV DB GETS ME ACCESS
export const url = 'https://api.snowstorm.net/api'

export type TRPCError = TRPCClientError<AppRouter>

export default defineNuxtPlugin(() => {
  const trpc = createTRPCNuxtClient<AppRouter>({
    links: [
      httpBatchLink({
        url,
        headers: () => {
          const access = localStorage.getItem('access')
          const refresh = localStorage.getItem('refresh')

          if (access || refresh) {
            return {
              'x-snowrpc-access': access || undefined,
              'x-snowrpc-refresh': refresh || undefined
            }
          }

          return {}
        }
      })
    ]
  })

  return {
    provide: {
      trpc
    }
  }
})
